.cpinput-group-text {
    display: flex;
    width: 90px;
    align-items: center;
    padding: 0.5rem 0.9rem;
    font-size: 0.8125rem;
    font-weight: 400;
    line-height: 1.5;
    color: var(--vz-body-color);
    text-align: center;
    white-space: nowrap;
    background-color: var(--vz-tertiary-bg);
    border: var(--vz-border-width) solid var(--vz-input-border-custom);
    border-radius: var(--vz-border-radius);
    margin-bottom: 0px;
}